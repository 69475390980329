import React, { useEffect } from "react";
import "../styles/ServicesPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ServicesPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-page-main">
      <Header />
      <div className="services-page">
        <h1>We delivered amazing creative works for you.</h1>
        <div className="services-page-services">
          <div className="services-page-service">
            <img src="/images/1732619293118231.jpg" alt="DAP Image" />
            <h2>Events Shooting</h2>
            <p>
              We specialize in capturing the essence of your special occasions,
              from corporate gatherings to private celebrations. Our experienced
              photographers ensure every moment is beautifully documented,
              creating lasting memories you'll cherish for years to come.
            </p>
          </div>
          <div className="services-page-service">
            <img src="/images/1732619427370070.jpg" alt="DAP Image" />
            <h2>Wedding</h2>
            <p>
              Our wedding photography services capture the magic of your special
              day, from intimate moments to grand celebrations. We focus on
              storytelling through stunning images, ensuring that every detail
              and emotion is beautifully preserved for you to cherish forever.
            </p>
          </div>
          <div className="services-page-service">
            <img src="/images/artela-catalog.jpg" alt="DAP Image" />
            <h2>Graphic Design and Marketing</h2>
            <p>
              We offer innovative graphic design and marketing solutions that
              enhance your brand's identity and visibility. Our team creates
              eye-catching designs and effective marketing strategies that
              captivate your audience and elevate your business to new heights.
            </p>
          </div>
        </div>
        <img src="/images/173261948032322-orig.jpg" alt="DAP Image" />
      </div>
      <Footer />
    </div>
  );
};

export default ServicesPage;
