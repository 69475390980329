import React, { useEffect, useState } from "react";
import "../styles/AboutPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AboutPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const targetCount = 7097;
  const [count, setCount] = useState<number>(targetCount);

  const spinDuration = 3000;
  const updateInterval = 100;

  useEffect(() => {
    const spinCounter = () => {
      const totalUpdates = spinDuration / updateInterval;
      let updatesRemaining = totalUpdates;

      const intervalId = setInterval(() => {
        const randomNum = Math.floor(Math.random() * targetCount);

        setCount(randomNum);

        updatesRemaining--;

        if (updatesRemaining <= 0) {
          clearInterval(intervalId);
          setCount(targetCount);
        }
      }, updateInterval);

      return () => clearInterval(intervalId);
    };

    spinCounter();
  }, [targetCount]);

  const formatNumber = (num: number): string => {
    return num.toString().padStart(4, "0");
  };

  const formattedCount = formatNumber(count);
  const firstDigit = formattedCount.charAt(0);
  const lastThreeDigits = formattedCount.slice(1);

  return (
    <div className="about-page-main">
      <Header />
      <div className="about-page">
        <div className="about-page-cover">
          <h1>Photo and video Studio</h1>
          <p>
            Our philosophy is to deliver creative and unique works to customer.
          </p>
        </div>
        <div className="about-page-text">
          <h1>Our Philosophy</h1>
          <p>
            We believe that creativity knows no bounds. Our philosophy centers
            on the idea that every project is a canvas waiting to be transformed
            into a masterpiece. By combining artistic vision with technical
            expertise, we aim to create captivating visuals that connect with
            audiences on a deeper level. We prioritize authenticity and
            originality, ensuring that our work reflects the unique essence of
            each client. Collaboration and open communication are key to our
            process, allowing us to craft tailored solutions that exceed
            expectations and bring your vision to life.
          </p>

          <div className="animate_counter_wrapper">
            <h2 className="counter">
              <span className={`digit`}>{firstDigit}</span>
              <span className={`digit`}>{lastThreeDigits}</span>
            </h2>
            <p>Happy Customers</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
