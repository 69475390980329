import React, { useEffect, useState } from "react";
import "../styles/Background.css";

const Background = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [zoomDirection, setZoomDirection] = useState("zoom-out");

  const images = [
    "/images/dap-img-3-orig.jpg",
    "/images/1KP01754-orig.jpg",
    "/images/dap-img-12-orig.jpg",
    "/images/dap-img-10-orig.jpg",
    "/images/1732619222274497-orig.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prev) => (prev + 1) % images.length);
      setZoomDirection((prev) => (prev === "zoom-in" ? "zoom-out" : "zoom-in"));
    }, 10000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      className={`background ${zoomDirection}`}
      style={{
        backgroundImage: `url(${images[currentImageIndex]})`,
        opacity: 1,
      }}
    />
  );
};

export default Background;
