import React from "react";
import "../styles/Footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <p>© Dream Art Production. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
