import React, { useEffect, useState, useRef } from "react";
import "../styles/PortfolioPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface ImageData {
  id: number;
  src: string;
  alt: string;
  isVertical: boolean;
}

const PortfolioPage: React.FC = () => {
  const images: ImageData[] = [
    {
      id: 1,
      src: "/images/dap-img-3.jpg",
      alt: "DAP Image 1 Description",
      isVertical: false,
    },
    {
      id: 2,
      src: "/images/173261942722459.jpg",
      alt: "DAP Image 2 Description",
      isVertical: false,
    },
    {
      id: 3,
      src: "/images/1732619222274497.jpg",
      alt: "DAP Image 3 Description",
      isVertical: true,
    },
    {
      id: 4,
      src: "/images/173261948032322.jpg",
      alt: "DAP Image 4 Description",
      isVertical: false,
    },
    {
      id: 5,
      src: "/images/1732619422733793.jpg",
      alt: "DAP Image 5 Description",
      isVertical: false,
    },
    {
      id: 6,
      src: "/images/dap-img-10.jpg",
      alt: "DAP Image 6 Description",
      isVertical: false,
    },
    {
      id: 7,
      src: "/images/dap-img-5.jpg",
      alt: "DAP Image 7 Description",
      isVertical: false,
    },
    {
      id: 8,
      src: "/images/dap-img-12.jpg",
      alt: "DAP Image 8 Description",
      isVertical: false,
    },
    {
      id: 9,
      src: "/images/dap-img-1.jpg",
      alt: "DAP Image 9 Description",
      isVertical: true,
    },
    {
      id: 10,
      src: "/images/DAP02895.jpg",
      alt: "DAP Image 10 Description",
      isVertical: false,
    },
    {
      id: 11,
      src: "/images/DAP04561.jpg",
      alt: "DAP Image 11 Description",
      isVertical: false,
    },
    {
      id: 12,
      src: "/images/1KP01754.jpg",
      alt: "DAP Image 12 Description",
      isVertical: false,
    },
    {
      id: 13,
      src: "/images/dap123.jpg",
      alt: "DAP Image 13 Description",
      isVertical: false,
    },
    {
      id: 14,
      src: "/images/DAP_4425.jpg",
      alt: "DAP Image 14 Description",
      isVertical: false,
    },
    {
      id: 15,
      src: "/images/DAP_3774.jpg",
      alt: "DAP Image 15 Description",
      isVertical: false,
    },
    {
      id: 16,
      src: "/images/dap456.jpg",
      alt: "DAP Image 15 Description",
      isVertical: false,
    },
  ];

  const imageRefs = useRef<(HTMLImageElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("reveal");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    imageRefs.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Header />
      <div className="portfolio-page">
        <div className="portfolio-images">
          {images.length === 0 ? (
            <p>No images available.</p>
          ) : (
            images.map((image, index) => (
              <img
                key={image.id}
                src={image.src}
                alt={image.alt}
                className={image.isVertical ? "vertical" : "horizontal"}
                ref={(el) => (imageRefs.current[index] = el)}
              />
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PortfolioPage;
