import React, { useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import "../styles/ContactPage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ContactPage: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const email = form.current?.email.value;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (form.current) {
      emailjs
        .sendForm(
          "service_ykzs7hq",
          "template_kfhfai8",
          form.current,
          "duATMmrg4f-n5wEeN"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Message sent successfully!");
            form.current?.reset();
          },
          (error) => {
            console.log(error.text);
            alert("There was an error sending the message.");
          }
        );
    }
  };

  return (
    <div className="contact-page-main">
      <Header />
      <div className="contact-page">
        <div className="contact-page-background">
          <h1>Let's connect</h1>
        </div>
        <div className="contact-page-form">
          <h1>Send a message</h1>
          <form ref={form} onSubmit={handleSubmit}>
            <div className="contact-form-row1">
              <div className="form-part">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  aria-label="Name"
                />
              </div>
              <div className="form-part">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  aria-label="Email"
                />
              </div>
              <div className="form-part">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  aria-label="Subject"
                />
              </div>
            </div>
            <div className="form-part">
              <textarea
                name="message"
                placeholder="Message"
                required
                aria-label="Message"
              ></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="contact-details">
          {/* <div className="contact-detail">
            <span>Location</span>
            <p>
              <a
                href="https://goo.gl/maps/ChIJBbHggkyTVRMR6oPifTWL4b4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Kocani
              </a>
            </p>
          </div> */}
          <div className="contact-detail">
            <span>Call Us</span>
            <p>
              {" "}
              <a href="tel:+38978366811" aria-label="Call +389 78 366 811">
                +389 78 366 811
              </a>
            </p>
          </div>
          <div className="contact-detail">
            <span>Send an email</span>
            <p>
              {" "}
              <a
                href="mailto:dreamartphotography4@gmail.com"
                aria-label="Email dreamartphotography4@gmail.com"
              >
                dreamartphotography4@gmail.com
              </a>
            </p>
          </div>
          <div className="contact-detail">
            <span>Social Media</span>
            <div className="contact-links">
              <p>
                <a
                  href="https://www.facebook.com/DreamArtPhotography17/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-icons"
                  aria-label="Facebook"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/dreamartphoto_graphy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-media-icons"
                  aria-label="Instagram"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.8419063370234!2d22.40858718738433!3d41.917757171198446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1355934c82e0b105%3A0xbee18b357de283ea!2sDream%20Art%20Production!5e0!3m2!1sen!2smk!4v1730451911131!5m2!1sen!2smk"
            title="Dream Art Production Location"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
