import React from "react";
import Header from "../components/Header";
import Background from "../components/Background";
import Footer from "../components/Footer";
import "../styles/Homepage.css";

const Homepage = () => {
  return (
    <div className="homepage">
      <Header />
      <Background />
      <Footer />
    </div>
  );
};

export default Homepage;
