import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false); // Close the menu on larger screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header>
      <div className="header-1st-row">
        <div className="logo">
          <Link to="/">
            <img
              src="/images/logo-dream-art-production.png"
              alt="Logo Dream Art"
            />
          </Link>
        </div>
        <div className="header-icons">
          <p>
            <a
              href="https://www.facebook.com/DreamArtPhotography17/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-icons"
              aria-label="Facebook"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
          </p>
          <p>
            <a
              href="https://www.instagram.com/dreamartphoto_graphy/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-media-icons"
              aria-label="Instagram"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
          </p>
          <p>
            <a
              href="mailto:dreamartphotography4@gmail.com"
              aria-label="Email dreamartphotography4@gmail.com"
            >
              <i className="fa-regular fa-envelope"></i>
            </a>
          </p>
          <p>
            <a href="tel:+38978366811" aria-label="Call +389 78 366 811">
              <i className="fa-solid fa-mobile-screen-button"></i>
            </a>
          </p>
        </div>
      </div>
      <nav className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link
              to="/"
              onClick={handleLinkClick}
              className={location.pathname === "/" ? "active" : ""}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              onClick={handleLinkClick}
              className={location.pathname === "/about" ? "active" : ""}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/portfolio"
              onClick={handleLinkClick}
              className={location.pathname === "/portfolio" ? "active" : ""}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              onClick={handleLinkClick}
              className={location.pathname === "/services" ? "active" : ""}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              onClick={handleLinkClick}
              className={location.pathname === "/contact" ? "active" : ""}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
      <div className="mobile-menu-icon">
        <a
          href="javascript:void(0);"
          className="icon"
          onClick={toggleMenu}
          aria-label="Toggle menu"
          role="button"
          tabIndex={0}
          aria-expanded={isMenuOpen}
        >
          <FontAwesomeIcon icon={isMenuOpen ? faXmark : faBars} />
        </a>
      </div>
    </header>
  );
};

export default Header;
